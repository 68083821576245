<!--
 * @Author: xiaziwen
 * @Date: 2021-01-10 12:23:52
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-10 13:33:09
 * @FilePath: \crm-education\src\views\student\my_textbook_send\index.vue
-->
<template>
  <iframe v-if="setUrl" width="100%" height="100%" :src="setUrl" frameborder="0" allowfullscreen="true"></iframe>
</template>

<script>
import api from '@/api'

export default {
  name: 'my_textbook_send',
  components: {},
  data() {
    return {
      roomId: ''
    }
  },
  beforeCreate() {},
  computed: {
    setUrl() {
      return `https://live.polyv.cn/watch/${this.roomId}` || ''
    }
  },
  created() {
    const { roomId } = this.$route.params
    this.roomId = roomId
  },
  mounted() {
    this.initOrganizationByDomain()
  },
  methods: {
    async initOrganizationByDomain() {
      const domain = window.location.origin || 100000
      let res = await api.protal.getOrganizationByDomain({ domain })
      if (res.code === 200 && res.data) {
        this.organizationId = res.data.organizationId
        this.protalTitle = res.data.organizationName || ''

        // 字符串存到数据库后再取出， 空格的编码从 '%20' -> '%C2%A0', 必须经过转换后才能正常渲染
        let str = res.data.studentLoginCopyright
        str = encodeURI(str).replaceAll('%C2%A0', '%20')
        str = decodeURI(str)
        res.data.studentLoginCopyright = str

        localStorage.setItem('organizationId', res.data.organizationId)
        this.$store.commit('setDomainSettings', res.data)
      } else {
        // TODO: 测试用
        if (
          process.env.NODE_ENV === 'development' ||
          (process.env.NODE_ENV === 'production' && process.env.VUE_APP_API_BASE_URL)
        ) {
          this.organizationId = 100000
          localStorage.setItem('organizationId', 100000)
        }
      }
      // TODO: 动态设置图标
      var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = `./${this.organizationId}.ico`
      document.getElementsByTagName('head')[0].appendChild(link)
    }
  }
}
</script>
<style lang="scss"></style>
